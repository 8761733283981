import React from "react";
import { ArwesThemeProvider, Text, Blockquote } from "@arwes/core";
import "./Roadmap.scss";

const Roadmap = () => {
  const [activate, setActivate] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => setActivate(!activate), 2000);
    return () => clearTimeout(timeout);
  }, [activate]);

  return (
    <ArwesThemeProvider>
      <div className="space-text">
        <Blockquote>
          <Text>
            <h5>PHASE 1: STAKING 💰</h5>
            <p>
              Each droid will immediately begin generating $ASTRO as soon as the
              mint sells out. $ASTRO will be used to fuel our ecosystem and
              future missions. More details on the $ASTRO token will be released
              on our official whitepaper soon.
            </p>
          </Text>
        </Blockquote>
        <Blockquote>
          <Text>
            <h5>PHASE 2: NFT ALPHA TOOL 🧠</h5>
            <p>
              We are creating a next-generation NFT marketplace analysis app / alpha
              tool and our holders will be given exclusive access to it for
              free. This will also help to bring value to our $ASTRO token.
            </p>
          </Text>
        </Blockquote>
        <Blockquote>
          <Text>
            <h5>PHASE 3: MERCH 🧢</h5>
            <p>
              We are teaming up with the best to bring high-quality merch to our
              droidz. Holders will get discounts on all items and can pay for
              items with $ASTRO.
            </p>
          </Text>
        </Blockquote>
        <Blockquote>
          <Text>
            <h5>PHASE 4: MORE 👾</h5>
            <p>More details coming soon...</p>
          </Text>
        </Blockquote>
      </div>
    </ArwesThemeProvider>
  );
};

export default Roadmap;
