import React from 'react';
import { ArwesThemeProvider, Text, FrameCorners, Figure } from '@arwes/core';
import './SpaceCard.scss';

const SpaceCard = () => {

  return (
    <ArwesThemeProvider>
      <FrameCorners>
        <div className='spaced display-6 py-5'>
          <Text>
            Astrodroidz is an NFT collection of 4,444 space droidz on the Solana blockchain.
            These droidz were created by Astro Labz to fight against the evil forces in the Metaverse.
            Each droid holds a key to the unknown.
          </Text>
        </div>
      </FrameCorners>
    </ArwesThemeProvider>
  );
};

export default SpaceCard;
