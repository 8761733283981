import { Helmet } from "react-helmet";
import SpaceCard from "../../components/space/SpaceCard";
import "./Home.scss";
import Roadmap from "../../components/space/Roadmap";

let summaryCollection = document.getElementsByTagName("summary");
let signsCollection = document.getElementsByClassName("faq-open-icon");

for (let i = 0; i < summaryCollection.length; i++) {
  summaryCollection[i].onclick = function () {
    if (signsCollection[i].innerHTML === "+")
      signsCollection[i].innerHTML = "—";
    else signsCollection[i].innerHTML = "+";
  };
}

const Home = () => {
  const Metadata = () => (
    <Helmet>
      <title>Astrodroidz</title>
      <meta
        name="description"
        content="An NFT collection with long-lasting utility on the Solana blockchain."
      />
      <meta name="keywords" content="Astrodroidz, NFT, ETH" />
    </Helmet>
  );

  return (
    <>
      <Metadata />
      <div id="about" className="space">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>

        <div className="hero">
          <div className="container d-flex align-items-center justify-content-center text-white h-100">
            <div className="row justify-content-center">
              <div className="col-10 text-center">
                <div className="card hero-card ">
                  <div className="space-card-body">
                    <SpaceCard></SpaceCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="roadmap" className="roadmap-bg roadmap space">
        <div className="container align-items-center justify-content-center">
          <h1 className="space-header">ROADMAP</h1>
          <Roadmap></Roadmap>
        </div>
      </section>

      <div className="space">
        <div className="slideshow">
          <div className="move">
            <img src="img/1.jpeg" />
            <img src="img/8.jpeg" />
            <img src="img/9.png" />
            <img src="img/gremlin.jpeg" />
            <img src="img/ninja.PNG" />
            <img src="img/6.jpeg" />
          </div>
          <div className="move">
            <img src="img/11.png" />
            <img src="img/snake.jpg" />
            <img src="img/gremlin.jpeg" />
            <img src="img/13.jpg" />
            <img src="img/ninja.PNG" />
            <img src="img/2.jpeg" />
          </div>
        </div>
      </div>

      <section id="team" className="roadmap-bg roadmap space">
        <div className="container align-items-center justify-content-center">
          <h1 className="space-header">TEAM</h1>
          <div className="container">
            <div className="row text-center justify-content-center">
              <div className="col-xl-3 col-sm-6 mb-5">
                <div className="bg-space rounded shadow-sm py-5 px-4">
                  <img
                    src="img/hayes.jpeg"
                    alt=""
                    width="150"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Hayes Roach</h5>
                  <span>Software Developer</span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a
                        href="https://twitter.com/roachyETH"
                        className="space-color social-link"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/in/hayesroach/"
                        className="space-color social-link"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://github.com/hayes-roach"
                        className="space-color social-link"
                      >
                        <i className="fa fa-github"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6 mb-5">
                <div className="bg-space rounded shadow-sm py-5 px-4">
                  <img
                    src="img/noah.png"
                    alt=""
                    width="150"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Noah Justice</h5>
                  <span>Artist & Marketing Lead</span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a
                        href="https://twitter.com/NoahETH"
                        className="space-color social-link"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="space-color social-link">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6 mb-5">
                <div className="bg-space rounded shadow-sm py-5 px-4">
                  <img
                    src="img/malcom.png"
                    alt=""
                    width="150"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Malcom Whitaker</h5>
                  <span>Business Development</span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a
                        href="https://twitter.com/MalcomWhitak3r"
                        className="space-color social-link"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="space-color social-link">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-xl-3 col-sm-6 mb-5">
                <div className="bg-space rounded shadow-sm py-5 px-4">
                  <img
                    src="img/hunter.jpeg"
                    alt=""
                    width="150"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Hunter Hines</h5>
                  <span>Business Development</span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/in/hunter-hines-623398137/"
                        className="space-color social-link"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://twitter.com/Hunter_Hines03"
                        className="space-color social-link"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-6 mb-5">
                <div className="bg-space rounded shadow-sm py-5 px-4">
                  <img
                    src="img/coby.png"
                    alt=""
                    width="150"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-0">Coby Miles</h5>
                  <span>Business Development</span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a
                        href="https://twitter.com/thecobra555"
                        className="space-color social-link"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="space-color social-link">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="roadmap-bg roadmap space">
        <div className="container align-items-center justify-content-center">
          <h1 className="space-header">FAQ</h1>
          <div className="container">
            <details className="faq-card">
              <summary>
                What is the total supply?{" "}
                <span className="faq-open-icon">+</span>
              </summary>
              <span className="faq-card-spoiler">
                There will be a total of 4,444 unique Astrodroidz that will be
                split between the pre-sale and public sale. Pre-sale will
                consist of 75% of the supply while the remaining will be
                reserved for the public sale.
              </span>
            </details>
            <details className="faq-card">
              <summary>
                How many droidz can I buy per transaction?{" "}
                <span className="faq-open-icon">+</span>
              </summary>
              <span className="faq-card-spoiler">
                Each wallet can mint up to three droidz
              </span>
            </details>
            <details className="faq-card">
              <summary>
                When will Astrodroidz launch?{" "}
                <span className="faq-open-icon">+</span>
              </summary>
              <span className="faq-card-spoiler">
                TBD - will be annouced soon!
              </span>
            </details>
            <details className="faq-card">
              <summary>
                What is the price of minting a droid?{" "}
                <span className="faq-open-icon">+</span>
              </summary>
              <span className="faq-card-spoiler">
                Each droid will be 2 SOL.
              </span>
            </details>
            <details className="faq-card">
              <summary>
                What does my droid get me?{" "}
                <span className="faq-open-icon">+</span>
              </summary>
              <span className="faq-card-spoiler">
                Minting an Astrodroid NFT gives you long-term digital utility,
                exlusive access to anything mentioned in the roadmap, life long
                friendship with 4,444 Astrodroidz, and a sick PFP.
              </span>
            </details>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
