import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-md navbar-dark sticky-top">
        <div className="container">
          <a className="navbar-brand" href="#about">
            <img className="logo" src="img/allwhite.svg" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#middle-nav"
            aria-controls="middle-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="middle-nav">
            <ul className="navbar-nav mx-auto mt-2 mt-lg-0">
              <li className="nav-item px-3">
                <a className="white-color nav-link h3 m-0" href="#about">
                  ABOUT
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="white-color nav-link h3 m-0" href="#roadmap">
                  ROADMAP
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="white-color nav-link h3 m-0" href="#team">
                  TEAM
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="white-color nav-link h3 m-0" href="#faq">
                  FAQ
                </a>
              </li>
            </ul>
            <div className="navbar-nav">
              <div className="row">
                <div className="d-flex">
                  <a
                    className="text-reset m-auto me-md-3 m-auto"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://discord.gg/APH9s3abkN"
                  >
                    <img className="social-media-icon" src="img/discord.png" />
                  </a>
                  <a
                    className="text-reset m-auto me-md-3  m-auto"
                    href="https://opensea.io/collection/astrodroidz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="social-media-icon" src="img/opensea.svg" />
                  </a>
                  <a
                    className="text-reset m-auto me-md-3 m-auto"
                    href="https://twitter.com/astrodroidzNFT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="social-media-icon" src="img/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
