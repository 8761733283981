import React from 'react';
import { BrowserRouter as Routerr, Route, Routes } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './pages/Home/Home';


const Router = () => {
  return (
    <Routerr>
      <Header />
      <Routes>
        <Route path='/'  element={<Home/>} />
      </Routes>
      <Footer />
    </Routerr>
  );
};

export default Router;
