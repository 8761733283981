import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='container-fluid footer pt-5'>
      <h5 className="row social-links">Join Our Community!</h5>
      <div className="info">
        <div className="row social-links">
          <a
            href='https://twitter.com/astrodroidzNFT'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-primary mx-1 mb-2 fab fa-twitter'
            aria-label='Twitter'
          >
          </a>
          <a
            href='https://discord.gg/APH9s3abkN'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-primary mx-1 mb-2 fab fa-discord'
            aria-label='Discord'
          >
          </a>
          <a
            href='https://www.instagram.com/astrodroidz'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-primary mx-1 mb-2 fab fa-instagram'
            aria-label='Instagram'
          >
          </a>
          <a
            href='https://tiktok.com/@astrodroidz'
            target='_blank'
            rel='noopener noreferrer'
            className='btn btn-primary mx-1 mb-2 fab fa-tiktok'
            aria-label='TikTok'
          >
          </a>
        </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center py-4'>
            <p className='credit'>
            Made with &#10084; by the Droidz
            </p>
          </div>
        </div>
    </div>
  );
}

export default Footer;

